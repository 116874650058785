import React from "react";
import RouteList from "./Routes/RouteList";
import {AuthContextProvider} from "./Context/AuthContext";

function App() {
  return (
    <AuthContextProvider>
      <RouteList />
    </AuthContextProvider>
    
  );
}

export default App;
