import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  AuthErrorCodes,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";

import { setDoc, doc } from "firebase/firestore";

import {auth, db} from "../Firebase/firebase-config";

const UserContext = createContext();

export const AuthContextProvider = ({children}) => {
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [authError, setAuthError] = useState({});

  const signup_user = async (signup_data) => {
    try{
      const signup_response = await createUserWithEmailAndPassword(
        auth,
        signup_data.email,
        signup_data.password
      );
      const user_ID = signup_response.user.uid;
      const currentDateTime = new Date();
      const ref = doc(db, "users", user_ID);
      await setDoc(ref, {
        name: signup_data.name,
        dob: signup_data.dob,
        grade: signup_data.grade,
        created_at: currentDateTime,
        updated_at: currentDateTime,
      });
      navigate("/overview");
      
    }catch(error){
        if (error.code === "auth/email-already-in-use") {
          alert("Email is already in use. Please try another email.");
          setAuthError(error.code);
        } else if (error.code === AuthErrorCodes.WEAK_PASSWORD) {
          alert("Password must be at least 6 characters long.");
          setAuthError(error.code);
        } else {
          console.log("Error: " + error.message);
          // Handle other signup errors as needed...
          setAuthError(error.code);
        }
    }
  };

  const signin_user = async (signin_data) => {
      try {
        await signInWithEmailAndPassword(
          auth,
          signin_data.email,
          signin_data.password
        );
        navigate("/overview");
        
      } catch (handledError) {
        console.log(handledError.message);
      }
  };

  const logout = () => {
    signOut(auth);
    navigate("/login");
    localStorage.clear();
    window.location.reload();
  };

  // get Auth user
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => {
      unsubscribe();
    };
  }, []);


  return (
    <UserContext.Provider
      value={{
        signup_user,
        signin_user,
        logout,
        user,
        authError,
        children,
      }}>
      {children}
    </UserContext.Provider>
  );

}

export const UserAuth = () => {
  return useContext(UserContext);
};