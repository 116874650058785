import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAcQz3PDsvlSCeme8kHyNX4KycEYvTp794",
  authDomain: "ict-suhashi-test.firebaseapp.com",
  projectId: "ict-suhashi-test",
  storageBucket: "ict-suhashi-test.appspot.com",
  messagingSenderId: "22661780763",
  appId: "1:22661780763:web:18f672d56da3b4e389efbe",
  measurementId: "G-C1JZ3CL3ZL",
};

const firebaseConfigProduction = {
  apiKey: "AIzaSyDcR_FaTw6TMde4j3LCUQfticXPK0EQWT0",
  authDomain: "ict-suhashi-deploy.firebaseapp.com",
  projectId: "ict-suhashi-deploy",
  storageBucket: "ict-suhashi-deploy.appspot.com",
  messagingSenderId: "616979715532",
  appId: "1:616979715532:web:f77998edd56121d08d7969",
  measurementId: "G-C9SV76YW7V",
};

let app;
app = initializeApp(firebaseConfigProduction);
// app = initializeApp(firebaseConfig);
// if(process.env.NODE_ENV === 'production'){
//   app = initializeApp(firebaseConfigProduction);
// }else{
//   app = initializeApp(firebaseConfig);
// }

export const db = getFirestore(app);
export default app;
export const auth = getAuth(app);
export const storage = getStorage(app);
export const provider = new GoogleAuthProvider();
