import React from 'react'

const CoursePopup = ({ obj })=>{
    return(

            <div className="fixed w-1/3 pb-5 overflow-hidden border rounded-md shadow-sm bottom-10 right-10 popup-box bg-card">
                
                  <img
                    src={obj.imageUrl}
                    alt="Student"
                    className="w-full h-auto"
                    width={200}
                    height={120}
                    style={{ aspectRatio: "200 / 120", objectFit: "fill" }}
                  />
                  <div className="mt-2 font-medium text-center">
                    {obj.name}
                   
                  </div>
                </div>
    )
}

export default CoursePopup;