import Sidebar1 from "./components/Sidebar1";
import Navbar1 from "./components/Navbar1";
import {useEffect} from "react";

const Dashboard = () => {

  const tabName = "/ Dashboard";

  useEffect(() => {
    document.title = "Dashboard - ICT Suhashi";
  }, []);

  return (
    <div className="flex">
      <Sidebar1 TabName={tabName} />
      <div className="flex-grow mt-4 sm:mt-0 lg:mt-0 overflow-y-auto flex-1">
        <Navbar1 TabName={tabName === "/ Dashboard" ? "" : tabName} />
        <div className="container mx-auto px-4 my-2">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
            <div
              className="rounded-lg border text-card-foreground shadow-sm bg-[#a0f0ed]"
              data-v0-t="card">
              <div className="flex flex-col space-y-1.5 p-6">
                <h3 className="text-2xl font-semibold leading-none tracking-tight">
                  10
                </h3>
                <p className="text-sm text-muted-foreground">Total Course</p>
              </div>
              <div className="flex items-center p-6">
                <button className="inline-flex items-center justify-center rounded-md text-sm font-medium text-white ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-[#35342f] hover:bg-accent hover:text-accent-foreground h-10 px-4 py-2">
                  See Details
                </button>
              </div>
            </div>
            <div
              className="rounded-lg border text-card-foreground shadow-sm bg-[#d9a8ff]"
              data-v0-t="card">
              <div className="flex flex-col space-y-1.5 p-6">
                <h3 className="text-2xl font-semibold leading-none tracking-tight">
                  10
                </h3>
                <p className="text-sm text-muted-foreground">Total Notes</p>
              </div>
              <div className="flex items-center p-6">
                <button className="inline-flex items-center justify-center rounded-md text-sm font-medium text-white ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-[#35342f] hover:bg-accent hover:text-accent-foreground h-10 px-4 py-2">
                  See Details
                </button>
              </div>
            </div>
            <div
              className="rounded-lg border text-card-foreground shadow-sm bg-[#ffccac]"
              data-v0-t="card">
              <div className="flex flex-col space-y-1.5 p-6">
                <h3 className="text-2xl font-semibold leading-none tracking-tight">
                  8/10
                </h3>
                <p className="text-sm text-muted-foreground">
                  Average Quiz Score
                </p>
              </div>
              <div className="flex items-center p-6">
                <button className="inline-flex items-center justify-center rounded-md text-sm font-medium text-white ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-[#35342f] hover:bg-accent hover:text-accent-foreground h-10 px-4 py-2">
                  See Details
                </button>
              </div>
            </div>
            <div
              className="rounded-lg border text-card-foreground shadow-sm bg-[#ace0f9]"
              data-v0-t="card">
              <div className="flex flex-col space-y-1.5 p-6">
                <h3 className="text-2xl font-semibold leading-none tracking-tight">
                  8
                </h3>
                <p className="text-sm text-muted-foreground">Finished Units</p>
              </div>
              <div className="flex items-center p-6">
                <button className="inline-flex items-center justify-center rounded-md text-sm font-medium text-white ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-[#35342f] hover:bg-accent hover:text-accent-foreground h-10 px-4 py-2">
                  See Details
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
