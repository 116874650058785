import React, {useEffect} from 'react'
import about_image from './Assets/139104.jpg'

import profile from "../Dashboard/Assets/user.jpeg"
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
const AboutTutor = () => {
  useEffect(() => {
      document.title = "About Tutor - ICT Suhashi";
  }, []);

  return (
    <div className='flex flex-col justify-between h-screen'>
      <div>
      <Navbar/>
      {/* <div className='relative flex items-center justify-center w-full h-auto overflow-hidden bg-black md:h-52'>
            <img className='w-full opacity-40 cover-fill' alt='' src={about_image}/>

            <div className='absolute'>
                <h1 className='text-4xl font-bold text-white'>About Us</h1>
            </div>
        </div> */}
        <div className='relative flex items-center justify-center w-full h-auto overflow-hidden bg-black md:h-52' style={{ backgroundImage: `url(${about_image})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>

        {/* Black overlay */}
        <div className='absolute inset-0 bg-black opacity-40'></div>

        {/* Content */}
        <div className='absolute inset-0 flex items-center justify-center'>
            <div>
                <h1 className='text-4xl font-bold text-white'>About Us</h1>
            </div>
      </div>
</div>

      <div className="flex flex-1 mt-4 overflow-y-auto sm:mt-0 lg:mt-0">
        <section className="w-full py-5 bg-[#ffffff] ">
          <div className="container px-4 mx-auto md:px-6">
            <div className="flex flex-col items-center space-y-4 text-center md:flex-row md:items-start md:text-left md:space-y-0">
              <div className="max-w-xs pr-1 md:pt-12 md:w-1/4 md:max-w-none">
                <img
                  
                  src={profile}
                  alt="Avatar"
                  className="object-contain object-center mx-auto rounded-sm w-60 h-60 md:w-64 md:h-64"
                  width={250}
                  height={250}
                  style={{ aspectRatio: "250 / 250", objectFit: "cover" }}
                />
              </div>
              <div className="w-full max-w-xs space-y-3 md:max-w-none">
                <h2 className="text-2xl font-bold tracking-tighter sm:text-2xl md:text-3xl lg:text-3xl">
                Suhashinee Bandara
                </h2>
                <p className="mx-auto text-gray-800 ">
                "Hello, I'm Suhashinee Bandara, I hold a Bachelor of Information Technology (BIT) and a
                Postgraduate Diploma in Education (PGDE) from the University of Colombo, complemented
                by my Microsoft Certified Professional (MCP) certification. With 18 years of experience, I've
                served as an Advanced Level Information Communication and Technology (ICT) English
                medium teacher in both national and international leading schools.
                My expertise extends across national Advanced Level ICT and Edexcel-International
                Advanced Level ICT curricula, where I've dedicated myself to equipping students with not
                just theoretical knowledge but also practical skills essential for success in today's
                technology-driven world."
                I've been an Assistant Examiner for the Advanced Level GCE Examination since 2019. This
                role has provided me with a deep understanding of assessment criteria, allowing me to
                refine my teaching strategies to better equip students for success in these examinations."
                "Welcome, students! Join our online teaching and learning platform (www.ictsuhashi.com
                web site), where you'll discover a wealth of resources including lesson videos, assessments,
                and Test papers evaluations tailored to help you excel in your ICT exams. Enrol today to
                access a comprehensive suite of tools designed to guide you toward success in your ICT
                studies. Let's embark on this learning journey together and achieve your goals!"
                </p>
                <div className="space-x-4">

                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      </div>
      <Footer/>
    </div>
  );
}

export default AboutTutor