
import shade1 from "./Assets/shade1.png";
import shade2 from "./Assets/shade2.png";
import shade3 from "./Assets/shade3.png";
import shade4 from "./Assets/shade4.png";

import Navbar1 from "./components/Navbar1";
import Sidebar1 from "./components/Sidebar1";
import {useEffect, useState} from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../Firebase/firebase-config";
import { UserAuth } from "../../Context/AuthContext";
import { Link } from "react-router-dom";
import Footer from './../Home/Components/Footer';
const Overview = () => {

  const [totalCourse, setTotalCourse]=useState(0);
  const [toalNotes, setTotalNotes]=useState(0);
  const [paidVideo, setPaidVideo]= useState(0);
  const [approvalPendingVideo, setApprovalPendingVideo]=useState(0);
  const [recentCourses, setRecentCourses]= useState([]);
  const [loading, setLoading] = useState(false);
  const [isSidebarVisible, setSidebarVisible] = useState(false);

  const showSideBar = () => {
    setSidebarVisible(true);
  }

  const { user }= UserAuth();
  const userID = user && user?.uid;
  console.log(userID)
   

  const getRecentCourses=async () =>{
    setLoading(true);
    const recentCourses=[]
    try{
      const recentRef=collection(db,"recents")
      const q = query(recentRef, where("userId", "==", userID));
      const querySnapshot=await getDocs(q);
      const sortSnapshot=querySnapshot.docs.sort((a, b) => b.data().date - a.data().date)
      const limitSnapshot=sortSnapshot.slice(0, 8)
      // querySnapshot.forEach(async(data)=>{
        limitSnapshot.forEach(async(data)=>{
        const coursesSnapshot= await getDocs(collection(db, `edu_level/${data.data().eduLevelId}/courses/`))
        
        // const course=coursesSnapshot.docs.filter(ref=> ref.id === data.data().courseId)
        const course=coursesSnapshot.docs.filter(ref=> ref.id === data.data().courseId)
        if(course.length!==0){
          recentCourses.push({
            course_id: data?.data().courseId,
            course_data:course[0]?.data(),
            eduLevel:data?.data().eduLevelId
          })
        }
        
      })
      setRecentCourses(recentCourses)
      setLoading(false);
    }catch(error){
      setLoading(false);
      console.log("ERROR",error.code, error.message);
    }
  }

  const getTotalPapers=async()=>{
    let count=0;
    try{
      const eduLevelSnapshot= await getDocs(collection(db, `edu_level/`));
      eduLevelSnapshot.docs.forEach(async (data)=>{
        
        const couressSnapshot=await getDocs(collection(db, `edu_level/${data.id}/papers`));
        count+=couressSnapshot.docs.length;
        setTotalNotes(count)
      })
      
    }catch(error){
      console.log(error.code, error.message);
    }
  }

  const getTotalCourses=async ()=>{
    let count=0;
    try{
      const eduLevelSnapshot= await getDocs(collection(db, `edu_level/`));
      eduLevelSnapshot.docs.forEach(async (data)=>{
        
        const couressSnapshot=await getDocs(collection(db, `edu_level/${data.id}/courses`));
        count+=couressSnapshot.docs.length;
        setTotalCourse(count)
      })
      
    }catch(error){
      console.log(error.code, error.message);
    }
  }

  const getPaidVideo = async ()=>{
    let paid=0;
    let pending=0;
    const userId = user && user?.uid;
    try {
      const paymentSnapshot = await getDocs(
        query(collection(db, "payments"), where("userId", "==", userId))
      );
  
      paymentSnapshot.docs.forEach((value) => {
        if (value.data().isAccept) {
          paid++;
          setPaidVideo(paid);
        } else {
          pending++;
          setApprovalPendingVideo(pending);
        }
      });
    } catch (error) {
      console.log("ERROR",error.code, error.message);
    }
  }
  
  const tabName = "/ Overview";

  useEffect(()=>{
    getRecentCourses();
  },[user])

  useEffect(()=>{
    getPaidVideo();
  },[user])

  useEffect(() => {
    getTotalCourses();
    getTotalPapers();
    document.title = "Overview - ICT Suhashi";
  }, []);

  return (
    <div className="flex h-screen">
      <Sidebar1 isVisible={isSidebarVisible} setVisible={setSidebarVisible}/>
      <div className="flex-1 flex-grow mt-4 overflow-y-scroll sm:mt-0 lg:mt-0">
        <Navbar1 TabName={tabName} onShowSideBar={showSideBar} />

        {/*  */}
        <div className="min-h-screen px-4 mx-auto my-2 ">
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
            <div
              className="rounded-lg border text-card-foreground shadow-sm bg-[#a0f0ed]"
              style={{
                backgroundImage: `url(${shade1})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="flex flex-col space-y-1.5 p-6">
                <h3 className="text-4xl font-semibold leading-none tracking-tight">
                  {totalCourse && totalCourse}
                </h3>
                <p className="text-xl text-muted-foreground">Total Course</p>
              </div>
              {/* <div className="flex items-center p-6">
                <button className="inline-flex items-center justify-center rounded-md text-sm font-medium text-white ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-[#35342f] hover:bg-accent hover:text-accent-foreground h-10 px-4 py-2">
                  See Details
                </button>
              </div> */}
            </div>
            <div
              className="rounded-lg border text-card-foreground shadow-sm bg-[#d9a8ff]"
              style={{
                backgroundImage: `url(${shade2})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="flex flex-col space-y-1.5 p-6">
                <h3 className="text-4xl font-semibold leading-none tracking-tight">
                  {toalNotes && toalNotes}
                </h3>
                <p className="text-xl text-muted-foreground">Total Notes</p>
              </div>
              {/* <div className="flex items-center p-6">
                <button className="inline-flex items-center justify-center rounded-md text-sm font-medium text-white ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-[#35342f] hover:bg-accent hover:text-accent-foreground h-10 px-4 py-2">
                  See Details
                </button>
              </div> */}
            </div>
            <div
              className="rounded-lg border text-card-foreground shadow-sm bg-[#ffccac]"
              style={{
                backgroundImage: `url(${shade3})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="flex flex-col space-y-1.5 p-6">
                <h3 className="text-4xl font-semibold leading-none tracking-tight">
                  {paidVideo && paidVideo}
                </h3>
                <p className="text-xl text-muted-foreground">Paid video</p>
              </div>
              {/* <div className="flex items-center p-6">
                <button className="inline-flex items-center justify-center rounded-md text-sm font-medium text-white ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-[#35342f] hover:bg-accent hover:text-accent-foreground h-10 px-4 py-2">
                  See Details
                </button>
              </div> */}
            </div>
            <div
              className="rounded-lg border text-card-foreground shadow-sm bg-[#ace0f9]"
              style={{
                backgroundImage: `url(${shade4})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="flex flex-col space-y-1.5 p-6">
                <h3 className="text-4xl font-semibold leading-none tracking-tight">
                  {approvalPendingVideo && approvalPendingVideo}
                </h3>
                <p className="text-xl text-muted-foreground">
                  Approval Pending video
                </p>
              </div>
              {/* <div className="flex items-center p-6">
                <button className="inline-flex items-center justify-center rounded-md text-sm font-medium text-white ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-[#35342f] hover:bg-accent hover:text-accent-foreground h-10 px-4 py-2">
                  See Details
                </button>
              </div> */}
            </div>
          </div>

          <div className="mt-8">
            <div className="flex items-center justify-between">
              <h2 className="text-xl font-bold">Recent Enrolled Course</h2>
            </div>
            <div className="container my-2">
              {loading ? (
                <div className="flex flex-col items-center mt-10">
                  <div className="w-24 h-24 border-t-2 border-b-2 border-gray-900 rounded-full animate-spin"></div>
                  <p className="mt-4 text-gray-900">Loading, please wait..</p>
                </div>
              ) : (
                ""
              )}
              {recentCourses && recentCourses.length === 0 && !loading ? (
                <div className="flex flex-col items-center mt-10">
                  <p>No data available. Please check back later.</p>
                </div>
              ) : (
                ""
              )}
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-5">
                {recentCourses &&
                  recentCourses
                    ?.sort((a, b) => {
                      //Assuming 'dateTime' is a property of each edulevelDoc
                      const dateTimeA = new Date(
                        `${a.course_data.date} ${a.course_data.time}`
                      );
                      const dateTimeB = new Date(
                        `${b.course_data.date} ${b.course_data.time}`
                      );
                      // return dateTimeB - dateTimeA;
                      return dateTimeA - dateTimeB;
                    })
                    ?.map((course) => (
                      <Link
                        className="w-full overflow-hidden border rounded-lg shadow-sm bg-card text-card-foreground"
                        to={`/chapters/${course.eduLevel}/${course.course_id}`}
                        key={course?.course_id}
                      >
                        <div className="pb-5">
                          <img
                            src={course?.course_data.imageUrl}
                            alt="Student"
                            className="w-full h-auto"
                            width={200}
                            height={100}
                            style={{
                              aspectRatio: "200 / 100",
                              objectFit: "cover",
                            }}
                          />
                          <div className="mt-2 font-medium text-center">
                            {course?.course_data.name}
                          </div>
                        </div>
                      </Link>
                    ))}
              </div>
            </div>
          </div>  
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Overview;
