import React, { useEffect, useState } from "react";
import { db } from "../../Firebase/firebase-config";
import {
  collection,
  getDocs,
} from "firebase/firestore";
import { useParams } from "react-router-dom";
import Sidebar1 from "./components/Sidebar1";
import Navbar1 from "./components/Navbar1";
import Footer from "../Home/Components/Footer";

const VideoViewer = () => {

  const { eduLevelId, courseId, chapterId, videoId } = useParams();
  const [video, setVideo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isSidebarVisible, setSidebarVisible] = useState(false);

  const showSideBar = () => {
    setSidebarVisible(true);
  };


  const tabName = "/ Grades / Courses / Lessons / Videos / Video Player";

  useEffect(() => {
    setLoading(true);
    document.title = "Video Player - ICT Suhashi";
    const fetchVideos = async () => {
      try {
        const videoSnapshot = await getDocs(collection(db, `edu_level/${eduLevelId}/courses/${courseId}/chapters/${chapterId}/videos`));

        const videos=videoSnapshot.docs.map((video)=>({
          video_id:video.id,
          video_data:video.data()
        }))

        const video=await videos.filter(ref=>ref.video_id===videoId);
        
        // Update the state with all videos after all iterations are done
        setVideo(video);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching videos: ", error);
      }
    };

    fetchVideos();
  }, []);



  
  return (
    <div className="flex h-screen">
      <Sidebar1 isVisible={isSidebarVisible} setVisible={setSidebarVisible} />
      <div className="flex-1 flex-grow overflow-y-auto sm:mt-0 lg:mt-0">
      <Navbar1 TabName={tabName} onShowSideBar={showSideBar} />
        {loading ? (
          <div className="flex flex-col items-center mt-10">
            <div className="w-24 h-24 border-t-2 border-b-2 border-gray-900 rounded-full animate-spin"></div>
            <p className="mt-4 text-gray-900">Loading, please wait..</p>
          </div>
        ) : (
          ""
        )}
          <div className="min-h-screen p-2">
          {video &&
            video?.map((videodata) => (
              <>
              <div className="overflow-hidden bg-black rounded-md" key={videodata.video_id} style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden' }}>
                <iframe
                  style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                  src={videodata.video_data.url}
                  title="Embedded Video"
                  allowFullScreen
                ></iframe>
                
              </div>
              <div className="mt-1 bg-gray-400 border rounded-sm"> 
              <h1 className="w-full mt-3 text-2xl font-bold text-center">{videodata.video_data.title}</h1>
              <p className="w-full pb-5 text-center">{videodata.video_data.description}</p>
              </div>
              </>
            ))}
            
          </div>
          <Footer/>
      </div>
    </div>
  );
};

export default VideoViewer;
