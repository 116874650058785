import React, { useEffect, useState } from "react";
import { db } from "../../Firebase/firebase-config";
import { collection, getDocs } from "firebase/firestore";
import {Link} from "react-router-dom";
import Sidebar1 from "./components/Sidebar1";
import Navbar1 from "./components/Navbar1";
import Footer from './../Home/Components/Footer';

const ManageCourses = () => {
  const [eduLevels, setEduLevels] = useState([]);
  const [loading, setLoading] = useState(false);

  const tabName = "/ Grades";
  const [isSidebarVisible, setSidebarVisible] = useState(false);

  const showSideBar = () => {
    setSidebarVisible(true);
  };
  
  useEffect(() => {
    setLoading(true);
    document.title = "Manage Courses - ICT Suhashi";
    const fetchEduLevels = async () => {
      const eduLevelCollection = collection(db, "edu_level");

      try {
        const eduLevelSnapshot = await getDocs(eduLevelCollection);

        // Map the documents to an array of objects
        const eduLevels = eduLevelSnapshot.docs.map((eduLevelDoc) => ({
          id: eduLevelDoc.id,
          data: eduLevelDoc.data(),
        }));

        // Update the state with eduLevels
        
        setEduLevels(eduLevels);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        //console.error("Error fetching edu_levels: ", error);
        alert("Something went wrong!!\n please try again.");
      }
    };

    fetchEduLevels();
    
  }, []);
  return (
    <div className="flex h-screen">
      <Sidebar1 isVisible={isSidebarVisible} setVisible={setSidebarVisible} />
      <div className="flex-1 flex-grow mt-4 overflow-y-auto sm:mt-0 lg:mt-0">
      <Navbar1 TabName={tabName} onShowSideBar={showSideBar} />
        {loading ? (
          <div className="flex flex-col items-center mt-10">
            <div className="w-24 h-24 border-t-2 border-b-2 border-gray-900 rounded-full animate-spin"></div>
            <p className="mt-4 text-gray-900">Loading, please wait..</p>
          </div>
        ) : (
          ""
        )}
        {(eduLevels.length===0 && !loading)?(
          <div className='flex flex-col items-center mt-10'>
          <p>No data available. Please check back later.</p>
      </div>
        ):("")}
        <div className="min-h-screen px-4 mx-auto my-2 ">
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-5">
            {eduLevels
            .sort((a, b) => {
              // Assuming 'dateTime' is a property of each edulevelDoc
              const dateTimeA = new Date(`${a.data.date} ${a.data.time}`);
              const dateTimeB = new Date(`${b.data.date} ${b.data.time}`);
              // return dateTimeB - dateTimeA;
              return dateTimeA-dateTimeB
          
            })
            .map((edulevelDoc) => (
              <Link
                className="w-full overflow-hidden border rounded-lg shadow-sm bg-card text-card-foreground"
                to={`/courses/${edulevelDoc.data.name.toLowerCase()}/${
                  edulevelDoc.id
                }`}
                key={edulevelDoc.id}>
                <div className="pb-5">
                  <img
                    src={edulevelDoc.data.imageUrl}
                    alt="Student"
                    className="w-full h-auto"
                    width={200}
                    height={100}
                    style={{ aspectRatio: "200 / 100", objectFit: "fill" }}
                    // style={{ aspectRatio: "200 / 120", objectFit: "cover" }}
                    
                  />
                  <div className="mt-2 font-medium text-center">
                    {edulevelDoc.data.name}
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default ManageCourses;
