import React, { useEffect, useState } from 'react'
import Sidebar1 from './components/Sidebar1';
import Navbar1 from './components/Navbar1';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../Firebase/firebase-config';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { FaFolderOpen } from "react-icons/fa";
import Footer from './../Home/Components/Footer';

function CourseList() {
    const [loading, setLoading]= useState(false)
    const [courses, setCourses]= useState([])
    const { eduLevelId }= useParams();
    const [isSidebarVisible, setSidebarVisible] = useState(false);

    const showSideBar = () => {
      setSidebarVisible(true);
    };
    useEffect(()=>{
        getCourses();
    },[])

    const getCourses=async()=>{
        setLoading(true);
        const courseCollection=collection(db, `edu_level/${eduLevelId}/courses`);
        try{
            const courseSnapshot= await getDocs(courseCollection);
            const courses= courseSnapshot.docs.map((courseDoc)=>({
                id: courseDoc.id,
                data: courseDoc.data()
            }))

            setCourses(courses);
            setLoading(false)
        }catch(error){
            setLoading(false)
            console.log(error)
        }
    }
  return (
    <div className='flex h-screen'>
        <Sidebar1 isVisible={isSidebarVisible} setVisible={setSidebarVisible} />
        <div className="flex-1 flex-grow mt-4 overflow-y-auto sm:mt-0 lg:mt-0">
            <Navbar1 TabName={"/ Grades / Courses"}  onShowSideBar={showSideBar}/>

        {loading ? (
          <div className="flex flex-col items-center mt-10">
            <div className="w-24 h-24 border-t-2 border-b-2 border-gray-900 rounded-full animate-spin"></div>
            <p className="mt-4 text-gray-900">Loading, please wait..</p>
          </div>
        ) : (
          ""
        )}
        {(courses.length===0 && !loading)?(
          <div className='flex flex-col items-center mt-10'>
          <p>No data available. Please check back later.</p>
      </div>
        ):("")}

        <div className='min-h-screen px-4 mx-auto my-2 '>
            {courses
            ?.sort((a, b) => {
              //Assuming 'dateTime' is a property of each edulevelDoc
              const dateTimeA = new Date(`${a.data.date} ${a.data.time}`);
              const dateTimeB = new Date(`${b.data.date} ${b.data.time}`);
              // return dateTimeB - dateTimeA;
              return dateTimeA-dateTimeB
          
            })
            .map((courseDoc)=>(
                <Link key={courseDoc.id} to={`/papers/${eduLevelId}/courses/${courseDoc.id}/chapters`} className='flex items-center flex-1 gap-3 p-4 rounded shadow-md'>
                    <FaFolderOpen />
                    {courseDoc.data.name}
                </Link>
            ))}
        </div>
        <Footer/>
        </div>
    </div>
  )
}

export default CourseList