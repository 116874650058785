import React, {useEffect} from 'react';
import Sidebar1 from './components/Sidebar1';
import Navbar1 from './components/Navbar1';

const SystemSetting = () => {
    const tabName = "/ System Settings";
    useEffect(() => {
      document.title = "System Settings - ICT Suhashi";
    }, []);
  return (
    <div className="flex">
      <Sidebar1 />
      <div className="flex-grow mt-4 sm:mt-0 lg:mt-0 overflow-y-auto flex-1">
        <Navbar1 TabName={tabName} />

        <div className="flex-grow">
          <p className="text-lg font-semibold mt-2 ml-2">System settings</p>

          <div className="flex mt-2 flex-row gap-[100px]md "></div>

          <div className="flex flex-col ml-4 py-4  ">
            <div className="w-full h-[320px] justify-between bg-[#FFF]  rounded-[20px]">
              <div className="justify-between mt-4 ml-4 mr-4 flex">
                <p className=" text-[#031F31] font-bold">System settings</p>
              </div>

              <div className="flex flex-row ml-[10px] pt-2 gap-9">
                <div className="border border-[#F0F2F4]  bg-[#fff]  h-[230px] w-[850px] rounded-lg">
                  <div className="flex flex-col gap-3 mt-3 ml-3"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SystemSetting;