import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../Firebase/firebase-config";
import { collection, query, where, getDocs } from "firebase/firestore";
import Sidebar1 from "./components/Sidebar1";
import Navbar1 from "./components/Navbar1";
import Footer from "../Home/Components/Footer";

const PaymentHistory = () => {
  const [payhistory, setPayHistory] = useState([]);
  const {orderID} = useParams();
  const [loading, setLoading] = useState(false);
  const [isSidebarVisible, setSidebarVisible] = useState(false);

  const showSideBar = () => {
    setSidebarVisible(true);
  };

  const tabName = "/ Payment History";

   useEffect(() => {
    setLoading(true);
    document.title = "Payment History - ICT Suhashi";
     const fetchPaymentHistory = async () => {
       try {
         if (orderID) {
           const paymentCollection = collection(db, "payments");
           const q = query(paymentCollection, where("id", "==", orderID));
           const querySnapshot = await getDocs(q);
           const paymentHistoryData = querySnapshot.docs.map((doc) => ({
             id: doc.id,
             ...doc.data(),
           }));
           setPayHistory(paymentHistoryData);
         }
         setLoading(false);
       } catch (error) {
        setLoading(false);
         alert("Something went wrong!!\n please try again.");
       }
     };
     // Call the fetchPaymentHistory function when the component mounts
     fetchPaymentHistory();
   }, [orderID]);

   return (
     <div className="flex h-screen">
       <Sidebar1 isVisible={isSidebarVisible} setVisible={setSidebarVisible} />
       <div className="flex-1 flex-grow mt-4 overflow-y-auto sm:mt-0 lg:mt-0">
       <Navbar1 TabName={tabName} onShowSideBar={showSideBar} />
         {loading ? (
          <div className="flex flex-col items-center mt-10">
            <div className="w-24 h-24 border-t-2 border-b-2 border-gray-900 rounded-full animate-spin"></div>
            <p className="mt-4 text-gray-900">Loading, please wait..</p>
          </div>
        ) : (
          ""
        )}
        {(payhistory.length===0 && !loading)?(
          <div className='flex flex-col items-center mt-10'>
          <p>No data available. Please check back later.</p>
      </div>
        ):("")}

         <div className="min-h-screen">
         {payhistory &&
           payhistory?.map((paymentHistory) => (
             <div className="max-w-2xl p-6 mx-auto my-8 bg-white rounded-lg shadow-lg" key={paymentHistory.id}>
               <div className="grid gap-4 md:grid-cols-2">
                 <div>
                   <div className="font-bold">
                     Order Id: {paymentHistory.id}
                   </div>
                   <div className="mt-2">
                     Amount: LKR {paymentHistory.payment}
                   </div>
                 </div>
                 <div>
                   <div className="font-bold">Date: {paymentHistory.date}</div>
                   <div className="mt-2">
                     Video Id: {paymentHistory.videoId}
                   </div>
                 </div>
               </div>
               <div className="flex items-center mt-4">
                 <div className="text-lg font-bold">Status:</div>
                 {paymentHistory.isAccept === true ? (
                   <div className="ml-2 bg-green-100 text-green-800 text-sm font-semibold px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900">
                     Paid
                     <svg
                       xmlns="http://www.w3.org/2000/svg"
                       width={24}
                       height={24}
                       viewBox="0 0 24 24"
                       fill="none"
                       stroke="currentColor"
                       strokeWidth={2}
                       strokeLinecap="round"
                       strokeLinejoin="round"
                       className="inline-block w-4 h-4 ml-1">
                       <polyline points="20 6 9 17 4 12" />
                     </svg>
                   </div>
                 ) : (
                   <div className="ml-2 bg-red-100 text-red-800 text-sm font-semibold px-2.5 py-0.5 rounded dark:bg-red-200 dark:text-red-900">
                     Approval Pending
                     <svg
                       xmlns="http://www.w3.org/2000/svg"
                       width={24}
                       height={24}
                       viewBox="0 0 24 24"
                       fill="none"
                       stroke="currentColor"
                       strokeWidth={2}
                       strokeLinecap="round"
                       strokeLinejoin="round"
                       className="inline-block w-4 h-4 ml-1">
                       <circle cx="12" cy="12" r="10" />
                       <line x1="12" y1="8" x2="12" y2="12" />
                       <line x1="12" y1="16" x2="12" y2="16" />
                     </svg>
                   </div>
                 )}
               </div>
               <div className="mt-4 text-lg font-bold">Payment Slip</div>
               <div className="mt-4">
                 <img
                   src={paymentHistory.slipUrl}
                   alt="Payment Slip"
                   className="w-full h-auto"
                   width={320}
                   height={200}
                   style={{ aspectRatio: "320 / 200", objectFit: "cover" }}
                 />
               </div>
             </div>
           ))}
         </div>
           <Footer/>
       </div>
       
     </div>
   );
};

export default PaymentHistory;
