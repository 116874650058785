import React, { useEffect, useState } from "react";
import { db } from "../../Firebase/firebase-config";
import { addDoc, collection, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import Sidebar1 from "./components/Sidebar1";
import Navbar1 from "./components/Navbar1";
import { UserAuth } from '../../Context/AuthContext';
import Footer from "../Home/Components/Footer";
import { truncateString } from './../../helpers/stringHelpers';
import CoursePopup from './components/CoursePopup';

const Chapters = () => {

  const { user } = UserAuth();
  const [chapters, setChapters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId]= useState('');
  const [isSidebarVisible, setSidebarVisible] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState([]);

  const showSideBar = () => {
    setSidebarVisible(true);
  };

  const {  eduLevelId, courseId } = useParams();
  const recentRef=collection(db, "recents")

  const tabName = "/ Grades / Courses / Lessons";

  const addRecentCourse=async ()=>{
    try{
      if(userId==='' || userId===undefined) return;
      const q=query(recentRef,where('userId', '==', userId),where('eduLevelId', '==', eduLevelId),where('courseId', '==', courseId))
      const querySnapshot = await getDocs(q);
      if(querySnapshot.empty){
        await addDoc(recentRef,{
          courseId: courseId,
          eduLevelId:eduLevelId,
          userId: userId,
          data: new Date().toLocaleDateString(),
          time: new Date().toLocaleTimeString(),
        })
      }else{
        const recentDoc=doc(db,"recents", querySnapshot.docs[0].id);
        await updateDoc(recentDoc,{
          data: new Date().toDateString(),
          time: new Date().toTimeString()
        })
      }
    }catch(error){
      console.log(error)
    }
  }

  useEffect(()=>{
    setUserId(user.uid)
    addRecentCourse();
  },[chapters, userId])

  useEffect(() => {
    setLoading(true);
    document.title = "Chapters - ICT Suhashi";
    const fetchChapters = async () => {
      try {
        const chapterSnapshot = await getDocs(
          collection(db, `edu_level/${eduLevelId}/courses/${courseId}/chapters`)
        );

        const chapters = chapterSnapshot.docs.map((chapter) => ({
          chapter_data: chapter.data(),
          chapter_id: chapter.id,
        }));
        setChapters(chapters);
        setLoading(false);
      } catch (error) {
        alert("Something went wrong!!\n please try again.");
      }
    };

    const fetchPaymentStatus = async () => {
      try {
        if (userId) {
          const paymentCollection = collection(db, "payments");
          const q = query(paymentCollection, where("userId", "==", userId));
          const querySnapshot = await getDocs(q);
          const paymentStatusData = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          console.log(paymentStatusData);
          setPaymentStatus(paymentStatusData);
        }
      } catch (error) {
        console.error("Error fetching payment history:", error);
      }
    };

    // Call the fetchPaymentStatus function when the component mounts
    fetchPaymentStatus();

    fetchChapters();
  }, [userId]);

  console.log(paymentStatus);

  return (
    <div className="flex h-screen">
      <Sidebar1 isVisible={isSidebarVisible} setVisible={setSidebarVisible} />
      <div className="flex-1 flex-grow mt-4 overflow-y-auto sm:mt-0 lg:mt-0">
        <Navbar1 TabName={tabName} onShowSideBar={showSideBar} />

        {loading ? (
          <div className="flex flex-col items-center mt-10">
            <div className="w-24 h-24 border-t-2 border-b-2 border-gray-900 rounded-full animate-spin"></div>
            <p className="mt-4 text-gray-900">Loading, please wait..</p>
          </div>
        ) : (
          ""
        )}
        {chapters.length === 0 && !loading ? (
          <div className="flex flex-col items-center mt-10">
            <p>No data available. Please check back later.</p>
          </div>
        ) : (
          ""
        )}

        <div className="min-h-screen px-4 mx-auto my-2">
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-5">
          {chapters
            ?.sort((a, b) => {
              //Assuming 'dateTime' is a property of each edulevelDoc
              const dateTimeA = new Date(
                `${a.chapter_data.date} ${a.chapter_data.time}`
              );
              const dateTimeB = new Date(
                `${b.chapter_data.date} ${b.chapter_data.time}`
              );
              // return dateTimeB - dateTimeA;
              return dateTimeA - dateTimeB;
            })
            ?.map((chapter) => (
              <div
                // className="grid items-center flex-1 grid-cols-3 gap-4 p-4 rounded shadow-md md:flex md:justify-between md:items-center md:flex-row"
                className="flex flex-col items-center gap-1 overflow-hidden rounded shadow-md "
                key={chapter.chapter_id}
              >
                <div className="data-grid">
                <img
                    src={chapter.chapter_data.imageUrl}
                    alt="Student"
                    className="w-full h-auto"
                    width={200}
                    height={120}
                    style={{ aspectRatio: "200 / 120", objectFit: "cover" }}
                  />
                  <CoursePopup obj={chapter.chapter_data}/>
                </div>
                <div className="mt-2 font-medium text-center">

                  {truncateString(chapter.chapter_data.name,20)}
                </div>
                

          <div className="flex items-center justify-center w-full gap-4">
            {chapter.chapter_data.price === 0 ? (
              <span className="bg-green-100 w-24 text-center  text-sm font-semibold px-2.5 py-0.5 rounded">
                Free
              </span>
            ) : (
              <span className="bg-green-100 w-24 text-center  text-sm font-semibold px-2.5 py-0.5 rounded">
                LKR {chapter.chapter_data.price}
              </span>
            )}
          </div>


                <div className="flex justify-center w-full px-2 pb-2 mt-auto">
                  {chapter.chapter_data.price === 0 ? (
                    <>
                      <Link
                        to={`/videos/${eduLevelId}/${courseId}/${chapter.chapter_id}`}
                        className="w-56 w-full h-10 py-2 text-center transition-colors bg-green-300 rounded-lg ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary ml-22"
                      >
                        Take Lesson
                      </Link>
                    </>
                  ) : (
                    <>
                      {paymentStatus.some(
                        (ps) => ps.chapterId === chapter.chapter_id
                      ) ? (
                        <button
                          className={`block py-2 w-56 rounded-lg  ${
                            paymentStatus.find(
                              (ps) => ps.chapterId === chapter.chapter_id
                            ).isAccept
                              ? "bg-green-300  " // Green for Approved
                              : "bg-red-300   " // Red for Approval Pending
                          }`}
                          disabled
                        >
                          {paymentStatus.find(
                            (ps) => ps.chapterId === chapter.chapter_id
                          ).isAccept ? (
                            <Link
                              className="text-center"
                              to={`/videos/${eduLevelId}/${courseId}/${chapter.chapter_id}`}
                            >
                              Show Video
                            </Link>
                          ) : (
                            <span className="flex items-center w-[15rem] justify-center">
                              <span className="mr-2">Approval Pending</span>
                            </span>
                          )}
                        </button>
                      ) : (
                        <>
                          <Link
                            to={`/payments/${chapter.chapter_id}/${chapter.chapter_data.driveFolderId}/${chapter.chapter_data.price}/${chapter.chapter_data.description}/${chapter.chapter_data.name}`}
                            className="w-56 h-10 px-4 py-2 text-center transition-colors bg-blue-300 rounded-lg ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
                          >
                            Enroll Now
                          </Link>
                        </>
                      )}
                    </>
                  )} 
                 
                </div>
                
                
                
              </div>
              
            ))}
            
            </div>
           
        </div>
        <Footer/>
      </div>
    </div>
  );
};

export default Chapters;


