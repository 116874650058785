import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../Firebase/firebase-config";
import { Link } from "react-router-dom";
import Course from "./AllCourses";
import EduLevelList from "./../../Dashboard/EduLevelList";

function Courses() {
  const [eduLevels, setEduLevels] = useState([]);
  const [loading, setLoading] = useState(false);
  const getCourseCount = async (eduId) => {
    try {
      const courseCollection = collection(db, `edu_level/${eduId}/courses`);
      const courseSnapshot = await getDocs(courseCollection);
      return courseSnapshot.size; // Return the count of documents
    } catch (error) {
      console.error("Error fetching course count:", error);
      return 0;
    }
  };
  useEffect(() => {
    setLoading(true);
    document.title = "Manage Courses - ICT Suhashi";
    const fetchEduLevels = async () => {
      const eduLevelCollection = collection(db, "edu_level");

      try {
        const eduLevelSnapshot = await getDocs(eduLevelCollection);

        // Map the documents to an array of objects
        // const eduLevels = eduLevelSnapshot.docs.map((eduLevelDoc) => ({
        //   id: eduLevelDoc.id,
        //   data: eduLevelDoc.data(),
        // }));

        const eduLevelDocs = eduLevelSnapshot.docs.map(async (eduLevelDoc) => {
          const count = await getCourseCount(eduLevelDoc.id);
          return {
            id: eduLevelDoc.id,
            data: eduLevelDoc.data(),
            count,
          };
        });
        // Update the state with eduLevels

        // setEduLevels(eduLevels);
        const resolvedEduLevels = await Promise.all(eduLevelDocs);
        setEduLevels(resolvedEduLevels);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        //console.error("Error fetching edu_levels: ", error);
        alert("Something went wrong!!\n please try again.");
      }
    };

    fetchEduLevels();
  }, []);
  return (
    <>
      <div className="pb-5">
        <div className="flex-1 mt-4 sm:mt-0 lg:mt-0">
          {loading ? (
            <div className="flex flex-col items-center mt-10">
              <div className="w-24 h-24 border-t-2 border-b-2 border-gray-900 rounded-full animate-spin"></div>
              <p className="mt-4 text-gray-900">Loading, please wait..</p>
            </div>
          ) : (
            ""
          )}
          {eduLevels.length === 0 && !loading ? (
            <div className="flex flex-col items-center mt-10">
              <p>No data available. Please check back later.</p>
            </div>
          ) : (
            ""
          )}
          <div className="container px-4 mx-auto md:my-2">
            <div className="">
              {eduLevels
                .sort((a, b) => {
                  // Assuming 'dateTime' is a property of each edulevelDoc
                  const dateTimeA = new Date(`${a.data.date} ${a.data.time}`);
                  const dateTimeB = new Date(`${b.data.date} ${b.data.time}`);
                  // return dateTimeB - dateTimeA;
                  return dateTimeA - dateTimeB;
                })
                .map(
                  (edulevelDoc) =>
                    edulevelDoc.count != 0 ? (
                      <div>
                        <div className="flex justify-center py-2 mt-5 bg-gray-100 border md:mt-0">
                          <h2 className="opacity-[90%]">
                            {edulevelDoc.data.name} Courses
                          </h2>
                          {/* <Course eduId="edulevelDoc.id"/> */}
                        </div>
                        <Course eduId={edulevelDoc.id} />
                      </div>
                    ) : (
                      ""
                    )
                  //   <Link
                  //     className="w-full overflow-hidden border rounded-lg shadow-sm bg-card text-card-foreground"
                  //     to={`/courses/${edulevelDoc.data.name.toLowerCase()}/${
                  //       edulevelDoc.id
                  //     }`}
                  //     key={edulevelDoc.id}>
                  //     <div className="pb-6">
                  //       <img
                  //         src={edulevelDoc.data.imageUrl}
                  //         alt="Student"
                  //         className="w-full h-auto"
                  //         width={200}
                  //         height={100}
                  //         style={{ aspectRatio: "200 / 100", objectFit: "cover" }}
                  //       />
                  //       <div className="mt-2 text-center">
                  //         {edulevelDoc.data.name}
                  //       </div>
                  //     </div>
                  //   </Link>
                )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Courses;
