import React, { useEffect, useState } from 'react'
import Sidebar1 from './components/Sidebar1';
import Navbar1 from './components/Navbar1';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../Firebase/firebase-config';
import { Link } from 'react-router-dom';
import { MdCastForEducation } from "react-icons/md";
import Footer from './../Home/Components/Footer';

function EduLevelList() {

    const [loading, setLoading]= useState(false)
    const [eduLevels, setEduLevels]= useState([])
    const [isSidebarVisible, setSidebarVisible] = useState(false);

    const tabName = "/ Grades";

    const showSideBar = () => {
      setSidebarVisible(true);
    };

    useEffect(()=>{
        getEduLevels();
    },[])

    const getEduLevels=async()=>{
        setLoading(true);
        const eduLevelCollection= collection(db, "edu_level");
        try{
            const eduLevelSnapshot= await getDocs(eduLevelCollection);
            const eduLevels = eduLevelSnapshot.docs.map((eduLevelDoc)=>({
                id: eduLevelDoc.id,
                data: eduLevelDoc.data()
            }))
            setEduLevels(eduLevels)
            setLoading(false);
        }catch(error){
            setLoading(false);
            console.log(error)
        }
    }

  return (
    <div className='flex h-screen'>
        <Sidebar1 isVisible={isSidebarVisible} setVisible={setSidebarVisible} />
        <div className="flex-1 flex-grow mt-4 overflow-y-auto sm:mt-0 lg:mt-0">
            <Navbar1 TabName={tabName} onShowSideBar={showSideBar} />

        {loading ? (
          <div className="flex flex-col items-center mt-10">
            <div className="w-24 h-24 border-t-2 border-b-2 border-gray-900 rounded-full animate-spin"></div>
            <p className="mt-4 text-gray-900">Loading, please wait..</p>
          </div>
        ) : (
          ""
        )}
        {(eduLevels.length===0 && !loading)?(
          <div className='flex flex-col items-center mt-10'>
          <p>No data available. Please check back later.</p>
      </div>
        ):("")}

        <div className='min-h-screen px-4 mx-auto my-2 '>
            {eduLevels
            ?.sort((a, b) => {
              //Assuming 'dateTime' is a property of each edulevelDoc
              const dateTimeA = new Date(`${a.data.date} ${a.data.time}`);
              const dateTimeB = new Date(`${b.data.date} ${b.data.time}`);
              // return dateTimeB - dateTimeA;
              return dateTimeA-dateTimeB
          
            })
            .map((eduLevelDoc)=>(
                <Link key={eduLevelDoc.id} to={`/papers/${eduLevelDoc.id}/courses`} className='flex items-center flex-1 gap-3 p-4 rounded shadow-md'>
                    <MdCastForEducation />
                    {eduLevelDoc.data.name}
                </Link>
            ))}
        </div>
        <Footer/>
        </div>
    </div>
  )
}

export default EduLevelList